<template>
    <div class="referalSet"/>
</template>

<script>
import {PreloaderMixin} from "../mixins/PreloaderMixin";

export default {
    name: "ReferalRedirect",
    mixins: [PreloaderMixin],
    props: ['id'],
    methods: {
        setReferal() {
            this.preloaderStartModal('.referalSet')
            require('axios').post('/api/referal/', {
                'user_id': this.id,
            }).then(data => {
                if (data.status === 200) {
                    this.$root.redirect('/')
                    this.preloaderStop('.referalSet')
                }
            }).catch(err => {
                this.$root.redirect('/')
                console.log('Произошла ошибка при установке реферальной cookie!')
                console.log(err)
            })

        },
    },


    mounted() {
        this.setReferal()
    }

}

</script>

<style scoped>
.referalSet {
    display: flex;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
}
</style>