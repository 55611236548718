import { render, staticRenderFns } from "./ReferalRedirect.vue?vue&type=template&id=630e5ffb&scoped=true&"
import script from "./ReferalRedirect.vue?vue&type=script&lang=js&"
export * from "./ReferalRedirect.vue?vue&type=script&lang=js&"
import style0 from "./ReferalRedirect.vue?vue&type=style&index=0&id=630e5ffb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "630e5ffb",
  null
  
)

export default component.exports